import clsx from 'clsx';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { LiaEnvelope, LiaEnvelopeOpen, LiaTrashSolid } from 'react-icons/lia';

import { Timestamp } from '@/components/date-time';
import { Button } from '@/components-new/button';
import { Checkbox, CheckboxGroup } from '@/components-new/checkbox';
import { Dialog, DialogActions, DialogBody, DialogTitle } from '@/components-new/dialog';
import { PageTitleRow } from '@/components-new/page-title-row';
import {
  Pagination,
  PaginationGap,
  PaginationList,
  PaginationNext,
  PaginationPage,
  PaginationPrevious
} from '@/components-new/pagination';
import { Seo } from '@/components-new/seo';

type Notification = {
  id: number;
  title: string;
  abstract: string;
  content: string;
  sentAt: string;
  read: boolean;
};

const notifications: Notification[] = [
  {
    id: 1,
    title: 'August Advantage+ Recording & Slides',
    abstract: 'We hope you enjoyed our August 2024 Advantage+ call! Our next call will be held on Tuesday, September 17 at 2:00 p.m. EDT.',
    content: '',
    sentAt: '2024-08-20T21:07:00',
    read: false
  },
  {
    id: 4,
    title: 'Medicaid in the News',
    abstract: 'Biosimilars Council applauds FDA draft guidance regarding interchangeability&hellip;',
    content: '<h2 class="text-2xl font-bold">FEDERAL</h2>\n' +
      '\n' +
      '<a class="text-primary-950" href="https://google.com">Biosimilars Council applauds FDA draft guidance regarding interchangeability</a>\n' +
      '<p class="py-3">The Executive Director of the Biosimilars Council agrees with the FDA’s position outlined in the June 2024 Draft Guidance Docket No. FDA–2017-D-0154, that data from a switching study or studies may no longer be necessary to support the licensure of interchangeable biosimilar medicines.</p>\n' +
      '\n' +
      '<a class="text-primary-950" href="https://google.com">Blood tests for cancer patients save lives. Access remains an issue.</a>\n' +
      '<p class="py-3">Millions today would benefit from advanced biomarker testing to guide oncology diagnosis and treatment, however, significant insurance hurdles create access gaps for diagnostics, presenting challenges for payers and policymakers.</p>\n' +
      '\n' +
      '<a class="text-primary-950" href="https://google.com">CMS Announces Release of State NOFO for the CGT Access Model</a>\n' +
      '<p class="py-3">Last week, CMS released the Notice of Funding Opportunity (NOFO) providing the opportunity for States to apply for Cooperative Agreement funding in the Cell and Gene Therapy (CGT) Access Model.</p>\n' +
      '\n' +
      '<a class="text-primary-950" href="https://google.com">IRA negotiations slash Medicare prices for Big Pharma blockbusters by up to 79%</a>\n' +
      '<p class="py-3">The White House has revealed significantly reduced prices for 10 prescription drugs affected by the first wave of Medicare negotiations mandated by the Inflation Reduction Act (IRA).</p>\n' +
      '\n' +
      '<a class="text-primary-950" href="https://google.com">Section 1115 Waiver Watch: Medicaid Pre-Release Services for People Who Are Incarcerated</a>\n' +
      '<p class="py-3">California will be the first state to implement the new Medicaid Reentry Section 1115 demonstration to support reentry for incarcerated people beginning in October 2024. Justice-involved reentry healthcare services aim to improve care transitions, increase continuity of health coverage, reduce disruptions in care, improve health outcomes, and reduce recidivism rates.</p>\n' +
      ' \n' +
      '<h2 class="text-2xl font-bold">STATE</h2>\n' +
      '\n' +
      '<h3 class="text-xl font-bold">Georgia</h3>\n' +
      '<a class="text-primary-950" href="https://google.com">Kemp commits $10.7M to advertise Pathways Medicaid Program for Georgia’s poor</a>\n' +
      '<p class="py-3">Georgia is launching a $10.7 million ad campaign to increase Medicaid health insurance program enrollment.</p>\n' +
      '\n' +
      '<h3 class="text-xl font-bold">Illinois</h3>\n' +
      '<a class="text-primary-950" href="https://google.com">Gov.Pritzker Takes Bill Action</a>\n' +
      '<p class="py-3">Illinois Governor Jay Robert Pritzker signed various healthcare bills resulting in 270 new laws.</p>\n' +
      '\n' +
      '<h3 class="text-xl font-bold">New York</h3>\n' +
      '<a class="text-primary-950" href="https://google.com">Governor Hochul announces $646 million to recruit and train thousands more health care workers across New York</a>\n' +
      '\n' +
      '<p class="py-3">New York’s Governor has announced that three Workforce Investment Organizations (WIO): The 1199SEIU Training and Employment Funds, Iroquois Healthcare Association, and Finger Lakes Performing Provider System, will receive $646 million to implement the Career Pathways Training (CPT) Program through the Medicaid Section 1115 Demonstration Waiver approved in January.</p>\n' +
      '\n' +
      '<h3 class="text-xl font-bold">North Carolina</h3>\n' +
      '<a class="text-primary-950" href="https://google.com">RFP #30-2024-001-DHB Children and Families Specialty Plan</a>\n' +
      '<p class="py-3">The North Carolina Department of Health and Human Services has awarded a $1,960,000,000 contract award to Blue Cross and Blue Shield for the new Children and Families Specialty Plan (CFSP).</p>\n' +
      '\n' +
      '<h3 class="text-xl font-bold">Wisconsin</h3>\n' +
      '<a class="text-primary-950" href="https://google.com">Report: Medicaid expansion, incentives for rural care could address workforce challenge</a>\n' +
      '<p class="py-3">Wisconsin’s Governor Tony Evers’ Task Force on the Healthcare Workforce released its final report this week, including a list of 10 broad policy recommendations and 26 related action items aimed at addressing labor shortages in the healthcare industry.</p>',
    sentAt: '2024-08-23T14:11:00',
    read: false
  },
  {
    id: 2,
    title: 'MMC Alert',
    abstract: 'There are 4 new Managed Medicaid updates.',
    content: '<ul class="list-disc"><li>GA Peach State Health Plan, 24Q3 PDL Change Notice, Effective September 23 – 08/26/24</li>\n' +
      '<li>KS Sunflower Health Plan, Sunflower Transitions to Availity Essentials, Effective October 21 – 08/23/24</li>\n' +
      '<li>KS Sunflower Health Plan, The Importance of Metabolic Monitoring – 08/23/24</li>\n' +
      '<li>NE Total Care Published Pharmacy Policies for Givinostat (Duvyzat) – 08/23/24</li></ul>',
    sentAt: '2024-08-26T17:45:00',
    read: true
  },
  {
    id: 3,
    title: 'FFS Alert',
    abstract: 'There are 13 new Fee-For-Service updates.',
    content: '<ul class="list-disc"><li>AR – Diabetic Preferred Products List updated 8/23/24</li>' +
      '<li>CT – Remaining 2024 RDAC Meeting Schedule</li>\n' +
      '<li>FL – Brand Name Preferred Drug List updated 8/13/24</li>\n' +
      '<li>ID – 8/15/24 PDL</li>\n' +
      '<li>IL – Remaining 2024 RDAC Meeting Schedule</li>\n' +
      '<li>IN – 9/20/24 DUR Board Meeting Agenda</li>\n' +
      '<li>MA – 9/11/24 DUR Board Meeting Agenda</li>\n' +
      '<li>MN – 9/18/24 Drug Formulary Committee Meeting Handout</li>\n' +
      '<li>ND – 9/4/24 DUR Board Meeting Handout</li>\n' +
      '<li>RI – 9/10/24 DUR Board Meeting Agenda</li>\n' +
      '<li>TN – 7/30/24 DUR Board Meeting Presentation Slides</li>\n' +
      '<li>VA – 10/8/24 Draft P&T Committee Meeting Agenda</li>\n' +
      '<li>VA – 7/23/24 Draft P&T Committee Meeting Minutes</li></ul>',
    sentAt: '2024-08-27T14:45:00',
    read: true
  }
];

const NotificationsPage = () => {
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [selectedNotifications, setSelectedNotifications] = useState<Notification[]>([]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState<Notification | undefined>(undefined);
  const [readStateAction, setReadStateAction] = useState<'read' | 'unread'>('read');

  useLayoutEffect(() => {
    const isIndeterminate = selectedNotifications.length > 0 && selectedNotifications.length < notifications.length;
    setChecked(selectedNotifications.length === notifications.length);
    setIndeterminate(isIndeterminate);
  }, [selectedNotifications]);

  const toggleAll = () => {
    setSelectedNotifications(checked || indeterminate ? [] : notifications);
    setChecked(!checked && !indeterminate);
    setIndeterminate(false);
  };

  const markSelectedAsRead = () => {
    selectedNotifications.forEach(notification => notification.read = true);
    setSelectedNotifications([]);
  };

  const markSelectedAsUnread = () => {
    selectedNotifications.forEach(notification => notification.read = false);
    setSelectedNotifications([]);
  };

  useEffect(() => {
    const markUnread = selectedNotifications.some(notification => notification.read);
    setReadStateAction(markUnread ? 'unread' : 'read');
  }, [selectedNotifications]);

  return (
    <>
      <Seo title="Notifications"/>
      <PageTitleRow title="Notifications"/>

      <div className="relative mt-8">
        {selectedNotifications.length > 0 && (
          <div className="absolute left-16 top-0 flex h-12 items-center space-x-3 bg-white sm:left-16">
            {readStateAction == 'unread' && (
              <Button outline title="Mark as unread" onClick={markSelectedAsUnread}>
                <LiaEnvelope className="size-5"/>
              </Button>
            )}
            {readStateAction == 'read' && (
              <Button outline title="Mark as read" onClick={markSelectedAsRead}>
                <LiaEnvelopeOpen className="size-5"/>
              </Button>
            )}
            <Button outline title="Delete">
              <LiaTrashSolid className="size-5"/>
            </Button>
          </div>
        )}

        <table className="min-w-full table-fixed divide-y divide-gray-300">
          <thead>
          <tr>
            <th scope="col" className="relative px-7 sm:w-12 sm:px-6">
              <CheckboxGroup>
                <Checkbox
                  color="secondary"
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={toggleAll}
                />
              </CheckboxGroup>
            </th>
            <th scope="col" className="min-w-48 py-3.5 pr-3 text-left text-sm font-normal text-zinc-700">
              Message
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-normal text-zinc-700">
              Sent At
            </th>
          </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
          {notifications.map((notification) => (
            <tr key={notification.id} className={selectedNotifications.includes(notification) ? 'bg-gray-50' : undefined}>
              <td className="relative px-7 sm:w-12 sm:px-6">
                {selectedNotifications.includes(notification) && (
                  <div className="absolute inset-y-0 left-0 w-0.5 bg-primary-950"/>
                )}
                <CheckboxGroup>
                  <Checkbox
                    color="secondary"
                    checked={selectedNotifications.includes(notification)}
                    value={notification.id.toString()}
                    onChange={(e) =>
                      setSelectedNotifications(
                        e
                          ? [...selectedNotifications, notification]
                          : selectedNotifications.filter((it) => it !== notification),
                      )
                    }
                  />
                </CheckboxGroup>
              </td>
              <td
                className={clsx(
                  'cursor-pointer whitespace-nowrap py-4 pr-3 text-sm font-medium',
                  selectedNotifications.includes(notification) ? 'text-primary-950' : 'text-gray-900',
                )}
                onClick={() => {
                  setCurrentNotification(notification);
                  setDialogOpen(true);
                  notification.read = true;
                }}
              >
                <p className={clsx(
                  notification.read ? 'font-normal' : 'font-bold'
                )}>{notification.title}</p>
                <p dangerouslySetInnerHTML={{ __html: notification.abstract }}/>
              </td>
              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                <Timestamp wordBreak="break-word" color="text-strong" value={notification.sentAt}/>
              </td>
            </tr>
          ))}
          </tbody>
        </table>

        <Pagination className="mt-2">
          <PaginationPrevious/>
          <PaginationList>
            <PaginationPage href="?page=1">1</PaginationPage>
            <PaginationPage href="?page=2">2</PaginationPage>
            <PaginationPage href="?page=3" current>3</PaginationPage>
            <PaginationPage href="?page=4">4</PaginationPage>
            <PaginationGap/>
            <PaginationPage href="?page=9">9</PaginationPage>
            <PaginationPage href="?page=10">10</PaginationPage>
          </PaginationList>
          <PaginationNext href="?page=4"/>
        </Pagination>
      </div>

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} size="5xl">
        <DialogTitle>{currentNotification?.title}</DialogTitle>
        <DialogBody className="text-sm/6 text-zinc-900 dark:text-white">
          {currentNotification?.content && (
            <div dangerouslySetInnerHTML={{ __html: currentNotification.content }}/>
          )}
        </DialogBody>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NotificationsPage;
