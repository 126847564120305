import { Box, DataTable, Menu, Text, Tip } from 'grommet';
import { Edit, More, Trash } from 'grommet-icons';
import { Star } from 'grommet-icons';
import { toString } from 'lodash';
import React from 'react';

import { TableEmptyPlaceholder } from '@/components/loading';
import { MenuItem, MenuItems } from '@/components/menu';
import DeleteCoverageTagPrompt from '@/features/drugs/components/delete-coverage-tag-prompt';
import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { Drug } from '@/products';
import { useNavigate } from '@/router';

type Props = {
  drug: Drug;
  coverageTags: CoverageTag[];
};

const CoverageTagsTable = ({ drug, coverageTags }: Props) => {
  const [coverageTagForDelete, setCoverageTagForDelete] = React.useState<CoverageTag | null>(null);

  const navigate = useNavigate();

  const handleEditCoverageTagClick = (coverageTag: CoverageTag) => {
    navigate('/drugs/:id/coverage-tags/:tagId/edit', { params: { id: toString(drug.id), tagId: toString(coverageTag.id) } });
  };

  const handleDeleteCoverageTagClick = (coverageTag: CoverageTag) => {
    setCoverageTagForDelete(coverageTag);
  };

  return (
    <Box pad={{ bottom: 'medium' }}>
      <Box margin={{ top: 'small' }} gap="small">
        <DataTable
          primaryKey="id"
          columns={[
            {
              property: 'name',
              header: 'Name',
              render: (coverageTag) => (
                <Box direction="row" align="center" gap="small">
                  <Text>{coverageTag.name}</Text>
                  {coverageTag.isDefault ? (
                    <Tip content="This is the default coverage tag that exists for every drug. It cannot be edited or deleted.">
                      <Star color="contrast-1" aria-label="This is the default coverage tag that exists for every drug. It cannot be edited or deleted." />
                    </Tip>
                  ) : null}
                </Box>
              )
            },
            {
              property: 'type',
              header: 'Type',
              render: (coverageTag) => (
                <Box direction="row" align="center" gap="small">
                  <Text>{coverageTag.type?.name}</Text>
                </Box>
              )
            },
            {
              property: 'appliesToAllPackagings',
              header: 'Applies To All Packagings?',
              render: (coverageTag) => (
                <Box direction="row" align="center" gap="small">
                  <Text>{coverageTag.appliesToAllPackagings ? 'Yes' : 'No'}</Text>
                </Box>
              )
            },
            {
              sortable: false,
              property: 'actions',
              size: '3.5rem',
              render: (coverageTag) => {
                if (!coverageTag.isDefault) {
                  return (
                    <MenuItems>
                      <Menu
                        hoverIndicator="background"
                        pad="none"
                        label={<Box as="span" pad="small"><More color="accent-1" /></Box>}
                        icon={false}
                        dropProps={{ align: { top: 'bottom', right: 'right' } }}
                        items={[
                          [
                            {
                              as: 'div',
                              label: <MenuItem icon={<Edit />} label="Edit" />,
                              onClick: () => handleEditCoverageTagClick(coverageTag),
                            }
                          ],
                          [
                            {
                              as: 'div',
                              label: <MenuItem icon={<Trash />} label="Delete" />,
                              onClick: () => handleDeleteCoverageTagClick(coverageTag)
                            }
                          ]
                        ]}
                      />
                    </MenuItems>
                  );
                }
              }
            }
          ]}
          data={coverageTags}
          sortable
          step={10}
          paginate
          placeholder={
            coverageTags.length === 0 && (
              <Box fill>
                <TableEmptyPlaceholder content="This drug does not have any coverage tags." />
              </Box>
            )
          }
        />
      </Box>
      {
        coverageTagForDelete && (
          <DeleteCoverageTagPrompt
            onClose={() => setCoverageTagForDelete(null)}
            coverageTag={coverageTagForDelete}
            drug={drug}
          />
        )
      }
    </Box>
  );
};

export default CoverageTagsTable;
