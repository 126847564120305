import { DateTime } from 'luxon';

import { BoardMeetingFilterData } from './use-board-meeting-service';

export const generateDefaultBoardMeetingFilters = (): BoardMeetingFilterData => {
  const today = DateTime.local();
  const oneYearFromToday = today.plus({ year: 1 });

  return {
    statesOrPools: [],
    drugs: [],
    classes: [],
    meetingTypes: [],
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    dateRangeBegin: today.toISODate(),
    // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    dateRangeEnd: oneYearFromToday.toISODate()
  };
};
