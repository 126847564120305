// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/admin/users`
  | `/admin/users/:id/edit`
  | `/bid-analyses`
  | `/bid-analyses/:id`
  | `/bid-analyses/:id/edit`
  | `/bid-analyses/new`
  | `/board-meetings`
  | `/callback`
  | `/changelog`
  | `/classifications`
  | `/classifications/:id/edit`
  | `/classifications/new`
  | `/clients`
  | `/clients/:id`
  | `/clients/:id/edit`
  | `/clients/:id/users`
  | `/clients/:id/users/:userId/edit`
  | `/clients/:id/users/new`
  | `/clients/new`
  | `/drug-coverage`
  | `/drug-utilizations`
  | `/drugs`
  | `/drugs/:id`
  | `/drugs/:id/coverage-tags`
  | `/drugs/:id/coverage-tags/:tagId/edit`
  | `/drugs/:id/coverage-tags/new`
  | `/drugs/:id/edit`
  | `/drugs/:id/packagings`
  | `/drugs/:id/packagings/:ndc`
  | `/drugs/new`
  | `/login`
  | `/market-baskets`
  | `/market-baskets/:id`
  | `/market-baskets/:id/edit`
  | `/market-baskets/new`
  | `/medicaid-datasets`
  | `/medicaid-datasets/new`
  | `/nadac`
  | `/notifications`
  | `/state-reports`
  | `/state-reports/manage-reports`
  | `/state-reports/manage-reports/:id`
  | `/state-reports/manage-reports/new`
  | `/user-settings`
  | `/user-settings/notifications`

export type Params = {
  '/admin/users/:id/edit': { id: string }
  '/bid-analyses/:id': { id: string }
  '/bid-analyses/:id/edit': { id: string }
  '/classifications/:id/edit': { id: string }
  '/clients/:id': { id: string }
  '/clients/:id/edit': { id: string }
  '/clients/:id/users': { id: string }
  '/clients/:id/users/:userId/edit': { id: string; userId: string }
  '/clients/:id/users/new': { id: string }
  '/drugs/:id': { id: string }
  '/drugs/:id/coverage-tags': { id: string }
  '/drugs/:id/coverage-tags/:tagId/edit': { id: string; tagId: string }
  '/drugs/:id/coverage-tags/new': { id: string }
  '/drugs/:id/edit': { id: string }
  '/drugs/:id/packagings': { id: string }
  '/drugs/:id/packagings/:ndc': { id: string; ndc: string }
  '/market-baskets/:id': { id: string }
  '/market-baskets/:id/edit': { id: string }
  '/state-reports/manage-reports/:id': { id: string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
