import { useModal } from '@shared/modal';
import { Anchor, Box, Button, ColumnConfig, DataTable } from 'grommet';
import { Add, Refresh, Trash } from 'grommet-icons';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArtiaButton } from '@/components/artia-button';
import { Timestamp } from '@/components/date-time';
import { useFilters } from '@/components/filters';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { Prompt } from '@/components/prompt';
import { Visible } from '@/components/visible';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Seo } from '@/components-new/seo';
import { MedicaidDatasetsProvider, useMedicaidDatasets } from '@/features/medicaid-datasets';
import { MedicaidDataset } from '@/features/medicaid-datasets';
import { MedicaidDatasetsFilterData, MedicaidDatasetsFilters } from '@/features/medicaid-datasets/components/medicaid-datasets-filters';
import { MedicaidSyncDialog } from '@/features/medicaid-datasets/components/medicaid-sync-dialog';
import { useCurrentUser } from '@/hooks/use-current-user';
import { insertIf } from '@/utils/arrays';

const MedicaidDatasets = () => {
  const { hasPolicies } = useCurrentUser();
  const {
    medicaidDatasets,
    loadMedicaidDatasets,
    deleteMedicaidDataset,
    loadingMedicaidDatasets
  } = useMedicaidDatasets();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const canManageMedicaidDatasets = hasPolicies(['canManageMedicaidDatasets']);

  const [filters, setFilters] = useFilters<MedicaidDatasetsFilterData>('medicaid-datasets', {});

  const filteredDatasets = useMemo(() => {
    const { title, year } = filters;

    return medicaidDatasets.filter(medicaidDataset =>
      (!title || medicaidDataset.title.toLowerCase().includes(title.toLowerCase()))
      && (!year || medicaidDataset.year === parseInt(year))
    );
  }, [filters, medicaidDatasets]);

  React.useEffect(() => {
    loadMedicaidDatasets();
    // TODO: revisit, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateMedicaidDataset = () => {
    navigate('/medicaid-datasets/new');
  };

  const openDeleteModal = (id: string) => {
    openModal(
      {
        component: (
          <Prompt
            header="Delete Medicaid Dataset"
            actions={[
              { name: 'Close', onClick: () => closeModal(), variant: 'outlined' },
              { name: 'Delete', onClick: () => onDeleteMedicaidDataset(id), variant: 'danger' }
            ]}
            message="Are you sure you want to delete this Medicaid Dataset?"
          />
        )
      },
      { type: 'layer', showCloseIcon: true, onClose: () => closeModal() }
    );
  };

  const onDeleteMedicaidDataset = (id: string) => {
    deleteMedicaidDataset(id);
    closeModal();
  };

  const [ refreshingDataset, setRefreshingDataset ] = useState<MedicaidDataset>();

  const handleRefreshDataset = (dataset: MedicaidDataset) => {
    setRefreshingDataset(dataset);
  };

  const handleCloseRefreshDatasetDialog = () => {
    setRefreshingDataset(undefined);
  };

  const columns: ColumnConfig<MedicaidDataset>[] = [
    {
      property: 'id',
      header: 'ID',
      render: dataset => (
        <Anchor href={`https://data.medicaid.gov/dataset/${dataset.id}`} target="_blank" referrerPolicy="no-referrer">
          {dataset.id}
        </Anchor>
      )
    },
    { property: 'title', header: 'Title' },
    { property: 'year', header: 'Year' },
    {
      property: 'lastImportedAt',
      header: 'Last Imported At',
      // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
      render: (dataset) => <Timestamp value={dataset.lastImportedAt} />
    },
    ...insertIf(
      canManageMedicaidDatasets,
      {
        property: 'actions',
        size: 'xsmall',
        sortable: false,
        render: (dataset: any) => (
          <Box direction="row" gap="xxsmall">
            <Button
              tip="Refresh"
              pad="small"
              hoverIndicator="background"
              margin="none"
              icon={<Refresh color="accent-1" size="medium" />}
              onClick={() => handleRefreshDataset(dataset)}
            />

            <Button
              tip="Delete"
              pad="small"
              hoverIndicator="background"
              margin="none"
              icon={<Trash color="accent-1" size="medium" />}
              onClick={() => openDeleteModal(dataset.id)}
            />
          </Box>
        )
      }
    )
  ];

  return (
    <>
      <Seo title="Medicaid Datasets" />
      <PageTitleRow title="Medicaid Datasets">
        <Visible when={canManageMedicaidDatasets}>
          <Box>
            <ArtiaButton
              a11yTitle="Add New Medicaid Dataset"
              icon={<Add/>}
              label="Add New"
              size="small"
              onClick={onCreateMedicaidDataset}
            />
          </Box>
        </Visible>
      </PageTitleRow>
      <Box pad={{ bottom: 'medium' }}>
        <Box margin={{ top: 'small' }} gap="small">
          <MedicaidDatasetsFilters defaultValue={filters} onSearch={setFilters} />
          <DataTable
            columns={columns}
            data={filteredDatasets}
            step={10}
            paginate
            placeholder={
              (loadingMedicaidDatasets || filteredDatasets.length === 0) &&
              <Box fill>
                {loadingMedicaidDatasets && <TableLoadingOverlay />}
                {!loadingMedicaidDatasets && filteredDatasets.length === 0 && <TableEmptyPlaceholder content="No Medicaid Dataset data is available." />}
              </Box>
            }
          />
        </Box>

        <MedicaidSyncDialog
          open={!!refreshingDataset}
          // @ts-expect-error TS(2322): Type 'MedicaidDataset | undefined' is not assignab... Remove this comment to see the full error message
          dataset={refreshingDataset}
          onCancel={handleCloseRefreshDatasetDialog}
          onRequestComplete={handleCloseRefreshDatasetDialog}
        />
      </Box>
    </>
  );
};

const MedicaidDatasetsPage = () => {
  return (
    <MedicaidDatasetsProvider>
      <MedicaidDatasets />
    </MedicaidDatasetsProvider>
  );
};

export default MedicaidDatasetsPage;
