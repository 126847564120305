import { useAsync } from '@shared/async';
import { useErrorHandler } from '@shared/errors';
import { useQueryClient } from '@tanstack/react-query';
import { Box, Menu, Text } from 'grommet';
import { Add } from 'grommet-icons';
import React, { useMemo } from 'react';

import CoverageTagChip from '@/features/coverage/components/coverage-tag-chip';
import { makeDrugQueryKey } from '@/features/drugs/api/use-drug';
import { useDrugPackagingsService } from '@/features/drugs/api/use-drug-packagings-service';
import { CoverageTag } from '@/features/drugs/types/coverage-tag';
import { Drug } from '@/features/drugs/types/drug';
import { DrugPackaging } from '@/features/drugs/types/drug-packaging';

type PackagingCoverageTagsCellProps = {
  drug: Drug;
  packaging: DrugPackaging;
  canManageCoverageTags: boolean;
};

export const PackagingCoverageTagsCell = ({ drug, packaging, canManageCoverageTags }: PackagingCoverageTagsCellProps) => {
  const { handleError } = useErrorHandler();
  const queryClient = useQueryClient();

  // only show non-default coverage tags at this level
  const coverageTags = useMemo(() => {
    const packagingTags = packaging?.coverageTags ?? [];
    const productLevelTags = drug?.coverageTags
      ?.filter(it => !it.isDefault && it.appliesToAllPackagings) ?? [];
    return [...packagingTags, ...productLevelTags];
  }, [packaging?.coverageTags]);

  const availableCoverageTags = useMemo(() => {
    const allCoverageTags = drug?.coverageTags?.filter(it => !it.appliesToAllPackagings) ?? [];
    return allCoverageTags.filter(it => !coverageTags.find(ct => ct.id === it.id));
  }, [drug?.coverageTags, coverageTags]);

  const { addCoverageTagToPackaging, removeCoverageTagFromPackaging } = useDrugPackagingsService();
  const addRequest = useAsync(addCoverageTagToPackaging);
  const removeRequest = useAsync(removeCoverageTagFromPackaging);

  const handleAddCoverageTag = async (coverageTag: CoverageTag) => {
    try {
      await addRequest.execute(drug.id, packaging.ndc, coverageTag.id);
      await queryClient.invalidateQueries({ queryKey: makeDrugQueryKey(drug.id) });
    } catch (error) {
      handleError(error as Error, {
        title: `Failed to add Coverage Tag ${coverageTag.name} to ${packaging.ndc}`,
        autoClose: false
      });
    }
  };

  const handleRemoveCoverageTag = async (coverageTag: CoverageTag) => {
    try {
      await removeRequest.execute(drug.id, packaging.ndc, coverageTag.id);
      await queryClient.invalidateQueries({ queryKey: makeDrugQueryKey(drug.id) });
    } catch (error) {
      handleError(error as Error, {
        title: `Failed to remove Coverage Tag ${coverageTag.name} from ${packaging.ndc}`,
        autoClose: false
      });
    }
  };

  const menuItems = availableCoverageTags.length > 0
    ? [
      availableCoverageTags.map(tag => ({
        label: tag.name,
        onClick: () => handleAddCoverageTag(tag)
      }))
    ]
    : [];

  return (
    <Box direction="row" gap="small" justify="between" align="center" width="100%">
      {coverageTags.length > 0 ? (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.25rem' }}>
          {coverageTags.map(tag => (
            <CoverageTagChip
              key={tag.id}
              coverageTag={tag}
              dismissible={canManageCoverageTags && !tag.appliesToAllPackagings}
              onDismiss={() => handleRemoveCoverageTag(tag)}
            />
          ))}
        </div>
      ) : (
        <Text>&mdash;</Text>
      )}
      {canManageCoverageTags && (
        <Menu
          label={<Add/>}
          icon={false}
          tip="Add Coverage Tag"
          a11yTitle="Add Coverage Tag"
          items={menuItems}
          dropAlign={{ top: 'bottom' }}
        />
      )}
    </Box>
  );
};
