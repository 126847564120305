import { ReactNode } from 'react';

type ButtonBarProps = {
  children: ReactNode;
}
export const ButtonBar = ({ children }: ButtonBarProps) => {
  return (
    <div className="flex flex-row justify-end gap-1">
      {children}
    </div>
  );
};
