import { Lookup } from './lookup';

export type CoverageTagLookup = {
  drugId: number;
  coverageTagId: number;
  drugName: string;
  coverageTagName?: string;
  isDefault: boolean;
  appliesToAllPackagings: boolean;
};

export const coverageTagToLookup = (coverageTagLookup: CoverageTagLookup): Lookup => {
  return {
    id: coverageTagLookup.coverageTagId,
    label: coverageTagLookup.isDefault ?
      coverageTagLookup.drugName :
      `${coverageTagLookup.drugName} ${coverageTagLookup.coverageTagName}`,
    nested: !coverageTagLookup.isDefault
  };
};
