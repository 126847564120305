import { safeJsonParse } from '@/utils/parsing';

type Features = {
  [key: string]: boolean;
};

type FeatureManager<T extends Features> = {
  getFeatures: () => T;
  getFeature: <K extends keyof T>(feature: K, defaultValue?: boolean) => boolean;
}

type FeatureManagerConfig<T extends Features> = {
  initialValue: T
  localStoragePrefix?: string;
}

const createFeatureManager = <T extends Features>({ initialValue, localStoragePrefix = 'features.' }: FeatureManagerConfig<T>): FeatureManager<T> => {
  const defaultFeatures = { ...initialValue };
  const features = { ...initialValue };

  const getFeature = <K extends keyof T>(feature: K, defaultValue = false): boolean => {
    const localStorageValue = window.localStorage.getItem(`${localStoragePrefix}${feature as string}`);
    features[feature] = localStorageValue ? JSON.parse(localStorageValue) : defaultFeatures[feature];
    return features[feature] ?? defaultValue;
  };

  const getFeatures = () => {
    return Object.keys(features).reduce((previousValue, key) => {
      return {
        ...previousValue,
        [key]: getFeature(key)
      };
    }, {} as T);
  };

  return {
    getFeatures,
    getFeature
  };
};

const FEATURES = {
  drugClassifications: safeJsonParse<boolean>(import.meta.env.VITE_FEATURES_DRUG_CLASSIFICATIONS),
  manageClients: safeJsonParse<boolean>(import.meta.env.VITE_FEATURES_MANAGE_CLIENTS),
  notifications: safeJsonParse<boolean>(import.meta.env.VITE_FEATURES_NOTIFICATIONS),
  userSettings: safeJsonParse<boolean>(import.meta.env.VITE_FEATURES_USER_SETTINGS)
} as const;

// @ts-expect-error TS(2322): Type '{ readonly drugClassifications: boolean | un... Remove this comment to see the full error message
export const featureManager = createFeatureManager({ initialValue: FEATURES });
