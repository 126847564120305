import * as Headless from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';

const sizes = {
  xs: 'sm:max-w-xs',
  sm: 'sm:max-w-sm',
  md: 'sm:max-w-md',
  lg: 'sm:max-w-lg',
  xl: 'sm:max-w-xl',
  '2xl': 'sm:max-w-2xl',
  '3xl': 'sm:max-w-3xl',
  '4xl': 'sm:max-w-4xl',
  '5xl': 'sm:max-w-5xl',
};

type DrawerProps = {
  size?: keyof typeof sizes,
  className?: string;
  children?: React.ReactNode;
} & Omit<Headless.DialogProps, 'as' | 'className'>

export const Drawer = ({ className, children, size = 'md',  ...props }: DrawerProps) => {
  return (
    <Headless.Dialog {...props} className="relative z-10">
      <Headless.DialogBackdrop
        transition
        className="fixed inset-0 bg-zinc-950/25 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
      />

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
            <Headless.DialogPanel
              transition
              className={clsx(
                className,
                sizes[size],
                'pointer-events-auto w-screen transition duration-300 ease-in-out data-[closed]:translate-x-full sm:duration-300'
              )
            }
            >
              <div className="flex h-full flex-col overflow-y-scroll rounded-l-md bg-white shadow-xl">
                {children}
              </div>
            </Headless.DialogPanel>
          </div>
        </div>
      </div>
    </Headless.Dialog>
  );
};

export const DrawerTitle = ({
  className,
  ...props
}: { className?: string } & Omit<Headless.DialogTitleProps, 'as' | 'className'>) => {
  return (
    <Headless.DialogTitle
      className={clsx(className, 'text-balance font-serif text-xl/8 font-semibold text-zinc-950 dark:text-white')}
      {...props}
    />
  );
};

export const DrawerBody = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  return <div {...props} className={clsx(className, 'px-4 sm:px-6 sm:py-4')} />;
};

export const DrawerHeader = ({ className, ...props }: React.ComponentPropsWithoutRef<'div'>) => {
  return <div {...props} className={clsx(className, 'sticky top-0 flex items-center justify-between border-b border-gray-200 bg-white p-4 sm:px-6 sm:py-4')} />;
};
