import { Box } from 'grommet';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { ArtiaButton } from '@/components/artia-button';

export type FilterFormProps = {
  /**
   * Dictates the style of the filter, defaults to 'standalone'. These are the styles applied by each:
   * * 'table': dark blue background with no bottom margin
   * * 'standalone': no background with a bottom border and margin
   */
  variant?: 'table' | 'standalone';

  /**
   * Any buttons to render on the left-hand side, optional.
   */
  actions?: ReactNode;

  /**
   * The form fields to render, required. Will be right-aligned with a reset button to the right of them.
   */
  children: ReactNode;

  /**
   * Chips, representing the values of the search filters, to render below the filters themselves, optional.
   */
  chips?: ReactNode;
};

/**
 * Wrapper for the common styling and layout used for filter forms.
 * **Doesn't dictate any behavior at all.**
 */
export const FilterForm = ({ variant = 'standalone', actions, children, chips }: FilterFormProps) => {
  return (
    <Box
      // @ts-expect-error TS(2322): Type '{ size: string; color: string; side: "bottom... Remove this comment to see the full error message
      border={variant === 'standalone'
        ? { size: 'small', color: 'light-3', side: 'bottom' }
        : null
      }
      // @ts-expect-error TS(2322): Type '{ bottom: string; } | null' is not assignabl... Remove this comment to see the full error message
      margin={variant === 'standalone' ? { bottom: 'medium' } : null}
      // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'Ba... Remove this comment to see the full error message
      background={variant === 'table' ? 'brand' : null}
      pad="medium"
      gap="medium"
    >
      <FormAndActionsContainer justify={actions ? 'space-between' : 'flex-end'}>
        {actions ? (
          <Box direction="row" gap="small">
            {actions}
          </Box>
        ) : null}
        <FormAndResetContainer>
          <FormFields>{children}</FormFields>
          <ArtiaButton type="reset" a11yTitle="Clear" label="Clear" />
        </FormAndResetContainer>
      </FormAndActionsContainer>
      {chips ? (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5rem', flexWrap: 'wrap' }}>
          {chips}
        </div>
      ) : null}
    </Box>
  );
};

const FormAndResetContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  width: fit-content;
`;

const FormAndActionsContainer = styled.div<{ justify?: 'space-between' | 'flex-end' }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify};
  align-items: flex-end;
  gap: 1rem;
`;

// use flexbox wrap instead of Grommet's Box wrap. Grommet's version looks weird and doesn't
// take gap into account
const FormFields = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: flex-end;
`;
