import { useAsync, UseAsyncStatus } from '@shared/async';
import { useAuthorization } from '@shared/auth/use-authorization';
import { featureManager } from '@shared/feature-flags/feature-manager';
import { Anchor, Box, ColumnConfig, DataTable, Text } from 'grommet';
import React, { useMemo, useState } from 'react';
import { LiaEditSolid, LiaPlusSolid, LiaTrashSolid } from 'react-icons/lia';

import { useFilters } from '@/components/filters';
import { TableEmptyPlaceholder, TableLoadingOverlay } from '@/components/loading';
import { UnsetLink } from '@/components/unset-link';
import { Button } from '@/components-new/button';
import { PageTitleRow } from '@/components-new/page-title-row';
import { Seo } from '@/components-new/seo';
import { DrugBase } from '@/features/drugs/types/drug';
import { useProductsService } from '@/products/api/use-products-service';
import { DeleteProductDialog } from '@/products/components/delete-product-dialog';
import { ProductFilterData, ProductFilters } from '@/products/components/product-filters';
import { insertIf } from '@/utils/arrays';

const ProductsPage = () => {
  const { searchProducts } = useProductsService();
  const { checkPolicies } = useAuthorization();
  const canManageDrugs = checkPolicies(['canManageDrugs']);

  const searchProductsAsync = useAsync(searchProducts);
  const searching = searchProductsAsync.status === UseAsyncStatus.Pending;

  const products = useMemo(() => searchProductsAsync.value ?? [], [searchProductsAsync.value]);
  const [currentFilters, setCurrentFilters] = useFilters<ProductFilterData>('drugs', {});

  const handleApplyFilters = (filters: { ndc?: string, name?: string, client?: string }) => {
    setCurrentFilters(filters);
  };

  React.useEffect(() => {
    void searchProductsAsync.execute(currentFilters);
    // TODO: revisit this, missing deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters]);

  const [ deletingProductId, setDeletingProductId ] = useState<number>();
  const handleDelete = (id: number) => {
    setDeletingProductId(id);
  };

  const handleCancelDelete = () => {
    setDeletingProductId(undefined);
  };

  const handleDeleteSuccessful = () => {
    setDeletingProductId(undefined);
    void searchProductsAsync.execute(currentFilters);
  };

  const columns: ColumnConfig<DrugBase>[] = [
    {
      property: 'name',
      header: 'Name',
      render: (product: DrugBase) => (
        <UnsetLink to={`/drugs/${product.id}`}>
          <Anchor as={Text} label={product.name} />
        </UnsetLink>
      )
    },
    {
      property: 'client.name',
      header: 'Client',
      render: (product: DrugBase) => (
        <Text>{product.client?.name}</Text>
      )
    },
    ...insertIf<ColumnConfig<DrugBase>>(
      featureManager.getFeature('drugClassifications'),
      {
        property: 'classification.name',
        header: 'Classification',
        render: (product: DrugBase) => (
          <Text>{product.classification?.name}</Text>
        )
      }
    ),
    ...insertIf<ColumnConfig<DrugBase>>(
      canManageDrugs,
      {
        property: 'actions',
        sortable: false,
        render: (product: DrugBase) => (
          <Box direction="row" gap="xxsmall" alignSelf="end">
            <Button
              plain
              title={`Edit ${product.name}`}
              href={`/drugs/${product.id}/edit`}
              aria-label={`Edit ${product.name}`}
            >
              <LiaEditSolid className="size-6"/>
            </Button>

            <Button
              plain
              title={`Delete ${product.name}`}
              aria-label={`Delete ${product.name}`}
              onClick={() => handleDelete(product.id)}
            >
              <LiaTrashSolid className="size-6"/>
            </Button>
          </Box>
        )
      }
    ),
  ];

  return (
    <>
      <Seo title="Drugs" />
      <PageTitleRow title="Drugs">
        {canManageDrugs && (
          <Button
            color="secondary"
            aria-label="Add Product"
            href="/drugs/new"
          >
            <LiaPlusSolid />
            Add New
          </Button>
        )}
      </PageTitleRow>
      <Box pad={{ bottom: 'medium' }}>
        <Box margin={{ top: 'small' }} gap="small">
          <ProductFilters defaultValue={currentFilters} onApplyFilters={handleApplyFilters} />
          <DataTable
            key="id"
            columns={columns}
            data={products}
            sortable
            step={10}
            paginate
            placeholder={
              (searching || products.length === 0) &&
              <Box fill>
                {searching && <TableLoadingOverlay />}
                {!searching && products.length === 0 && <TableEmptyPlaceholder content="No Product data is available." />}
              </Box>
            }
          />
        </Box>

        <DeleteProductDialog
          open={!!deletingProductId}
          // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
          productId={deletingProductId}
          onClose={handleCancelDelete}
          onDeleteComplete={handleDeleteSuccessful}
        />
      </Box>
    </>
  );
};

export default ProductsPage;
