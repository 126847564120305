import React from 'react';

type UserSettingProps = {
  title: React.ReactNode;
  children?: React.ReactNode;
};

export const UserSetting = ({ title, children }: UserSettingProps) => {
  return (
    <div className="items-center pt-6 sm:flex">
      <dt className="sm:w-64 sm:pr-6">{title}</dt>
      <dd className="flex items-center justify-between gap-x-6 sm:flex-auto">
        {children}
      </dd>
    </div>
  );
};

type UserSettingsListProps = {
  children: React.ReactNode;
};

export const UserSettingsList = ({ children }: UserSettingsListProps) => {
  return (
    <dl className="mt-6 space-y-6 divide-y border-t">
      {children}
    </dl>
  );
};
