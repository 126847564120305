import { Box, FormField, TextInput } from 'grommet';
import { Search } from 'grommet-icons';
import React from 'react';

import { GrommetFilterForm } from '@/components/filters';

type ProductFiltersProps = {
  defaultValue: ProductFilterData;
  onApplyFilters: (data: ProductFilterData) => void;
}

export const ProductFilters = ({ defaultValue, onApplyFilters }: ProductFiltersProps) => {
  return (
    <GrommetFilterForm
      defaultValue={defaultValue}
      emptyValues={{ ndc: '', name: '', client: '' }}
      onChange={(value) => onApplyFilters(value)}
    >
      <Box width="medium">
        <FormField
          name="name"
          htmlFor="name"
          margin="none"
        >
          <TextInput type="search" icon={<Search color="accent-1" />} id="name" name="name" placeholder="Search By Drug Name" />
        </FormField>
      </Box>
      <Box width="medium">
        <FormField
          name="ndc"
          htmlFor="ndc"
          margin="none"
        >
          <TextInput type="search" icon={<Search color="accent-1" />} id="ndc" name="ndc" placeholder="Search By NDC" />
        </FormField>
      </Box>
      <Box width="medium">
        <FormField
          name="client"
          htmlFor="client"
          margin="none"
        >
          <TextInput type="search" icon={<Search color="accent-1" />} id="client" name="client" placeholder="Search By Client Name" />
        </FormField>
      </Box>
    </GrommetFilterForm>
  );
};

export type ProductFilterData = { ndc?: string, name?: string, client?: string };
